import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

// 获取管理员子账号
export function getAcountChild(data) {
  return request({
    url: '/externaladmin/authService/user/childrenListByMainId',
    method: 'post',
    data
  })
}

export function subAccount(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/childrenListByMainId',
    method: 'post',
    data
  })
}

export function photoData(params) {
  return request({
    url: '/api/image/image/',
    method: 'get',
    params
  })
}

export function getcategory(data) {
  return request({
    url: '/externaladmin/productService/imageCategory/list',
    method: 'post',
    data
  })
}
//
export function getDetail(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: 'externaladmin/productService/productPrototypeCollection/productCustomGetById',
    method: 'post',
    data
  })
}
// 禁用图片
export function disablePictureApi(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/image/batchDisable',
    method: 'post',
    data
  })
}
// 启用图片
export function enablePictureApi(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/image/batchCancelDisable',
    method: 'post',
    data
  })
}

// 实名认证
export function getByUserId(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/userCertificateInfo/getByUserId',
    method: 'post',
    data
  })
}

// 运单号显示
export function updateWaybillStatus(data) {
  return request({
    url: '/externaladmin/authService/user/updateWaybillStatus',
    method: 'post',
    data
  })
}

// 导出价格表
export function exportPriceExcel(data) {
  return request({
    url: '/externaladmin/productService/priceList/leadingOut',
    method: 'post',
    responseType: 'blob',
    data
  })
}

// 侵权权限修改
export function tortSignUpdate(data) {
  return request({
    url: '/externaladmin/authService/user/tortSignUpdate',
    method: 'post',
    data
  })
}
